/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import { withRouter } from "react-router-dom";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import { getProductStockListByProjects, getStockDetail } from "../../crud/auth.crud";
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { BrandingWatermark } from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { paginationTexts } from '../../../_metronic/utils/utils';
import { CircularProgress } from "@material-ui/core";

class Stocks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reportsData: [],
            reportsCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 25,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentCategory: null,
            validated: false,
            disabledBtn: false,
            activeTab: 0,
            user_id: "",
            project_id: "",
            dloading: false,
            projectsStock: []
        };
        this.inputRef = createRef();

    }

    componentDidMount = async () => {
        this.getStockDetail();
    }

    getStockDetail = async (searchData) => {
        this.setState({dloading: true});
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var activePage = this.state.activePage;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        await getStockDetail(authToken, search, limitV, activePage).then(result => {
            this.setState({
                dloading: false,
                reportsData: result.data.payload ? result.data.payload.data.rows : [],
                reportsCount: result.data.payload && result.data.payload.data.count
            });

        }).catch(err => {
            this.setState({
                dloading: false,
                reportsData: [],
                reportsCount: 0
            });
            if (err.response && (err.response.data.message === "jwt expired")) {
                window.location.href = "/admin/logout";
            }
        });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getStockDetail();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        if (event.target.value.length === 0) {
            this.getStockDetail();
        }
    };

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getStockDetail();
        }, 500);
    }

    handleModal = (value, currentCategory) => {
        if (value === "stock_list") {
            this.setState({ modalType: value, show: true, currentCategory, loading: true });
            setTimeout(() => {
                this.getProductStockListByProjects(currentCategory.id, currentCategory.projectsArr);
            }, 500);
        } else {
            this.setState({ modalType: value, show: true, currentCategory });
        }
    }

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getStockDetail();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    getProductStockListByProjects = async (id, project_arr) => {

        const { authToken } = this.props;
        var obj = {
            id: id,
            projectsArr: JSON.stringify(project_arr)
        }
        await getProductStockListByProjects(authToken, obj).then(async result => {
            this.setState({
                projectsStock: result.data.payload ? result.data.payload.data : [],
                loading: false
            });

        }).catch(err => {
            this.setState({
                projectsStock: [],
                loading: false
            });
            if (err.response && (err.response.data.message === "jwt expired")) {
                window.location.href = "/admin/logout";
            }
        });
    }

    renderModalBody = () => {
        const { isFocus, modalType, loading, projectsStock } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };

        if (modalType === "stock_list") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }} className="text-dark">Product Stock</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    {loading ?
                        <div className="text-center py-3" ><CircularProgress />
                        </div> :
                        <><Modal.Body className="pt-0 max-h-300px overflow-auto">
                            <div className='fv-row mb-4'>
                                <Table striped responsive bordered hover className="table-list-header m-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center"><b>Project Name</b>
                                            </th>
                                            <th className="text-center"><b>Open Balance</b></th>
                                            <th className="text-center"><b>Close Balance</b></th>
                                            <th className="text-center"><b>Total Balance</b></th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-center">
                                        {projectsStock.map(prodItem => {
                                            return (
                                                <tr key={prodItem.project_name}>
                                                    <td>
                                                        <h6 className={'font-weight-bold text-muted'}>{prodItem.project_name}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 className={'font-weight-bold text-muted'}>{prodItem.open}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 className={'font-weight-bold text-muted'}>{prodItem.close}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 className={'font-weight-bold text-muted'}>{prodItem.open + prodItem.close}</h6>
                                                    </td>
                                                </tr>)
                                        }
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" className="ml-auto mr-3" variant="success" onClick={() => this.handleClose()}>
                                    Close
                                </Button>
                            </Modal.Footer></>}
                </div>
            );
        }
    }

    render() {
        const { reportsData, reportsCount, activePage, limit, searchValue, dloading, isFocus, startDate, endDate } = this.state;
        const customStyle = isFocus ? styleSearch
            : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-section bg-white px-4 py-2 border-top">
                            <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                <div className="row">
                                    <div className="input-group align-self-center col-9 col-md-3 mb-0">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                <i className="flaticon2-search-1" />
                                            </span>
                                        </div>

                                        <input
                                            type="text"
                                            ref={this.inputRef}
                                            placeholder="Search..."
                                            value={searchValue}
                                            onFocus={() => this.changeFocus()}
                                            style={customStyle}
                                            onChange={this.handleSearchChange}
                                            className="form-control kt-quick-search__input h-auto"
                                        />
                                        {(searchValue) && (
                                            <div className="input-group-append" onClick={this.clear}>
                                                <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                    <i className="la la-close kt-quick-search__close"
                                                        style={{ display: "flex" }} />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mr-md-3">
                                        <Button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                            onClick={this.handleSubmit}>
                                            Search
                                        </Button>
                                    </div>
                                    <div className="mr-3 ml-3 ml-md-0">
                                        <Button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                            onClick={this.handleReset}>
                                            Reset</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <Portlet className={'shadow-none'}>
                    <PortletBody>
                    {dloading ? <div className="text-center py-3" ><CircularProgress />
                        </div> :
                        <Table striped responsive bordered hover className="table-list-header m-0">
                            <thead>
                                <tr>
                                    <th className="text-center"><b>Brand Name</b>
                                    </th>
                                    <th className="text-center">
                                        <b>Product Name</b>
                                    </th>
                                    <th className="text-center"><b>Open Balance</b></th>
                                    <th className="text-center"><b>Close Balance</b></th>
                                    <th className="text-center"><b>Total Balance</b></th>
                                    <th className="text-center"><b>Actions</b></th>
                                </tr>
                            </thead>

                            <tbody className="text-center">
                                {reportsData.length > 0 ?
                                    reportsData.map((item, index) =>
                                        <tr key={item.id}>
                                            <td>
                                                <h6 className={'font-weight-bold text-muted'}>{item.brand_name}</h6>
                                            </td>
                                            <td>
                                                <h6 className={'font-weight-bold text-muted'}>{item.name}</h6>
                                            </td>
                                            <td>
                                                <h6 className={'font-weight-bold text-muted'}>{item.balance}</h6>
                                            </td>
                                            <td>
                                                <h6 className={'font-weight-bold text-muted'}>{item.close}</h6>
                                            </td>
                                            <td>
                                                <h6 className={'font-weight-bold text-muted'}>{item.balance + item.close}</h6>
                                            </td>
                                            <td className="text-center">
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={<Tooltip id="documentations-tooltip">View</Tooltip>}>
                                                    <a className="kt-menu__link-icon flaticon-eye pr-4 text-warning"
                                                        style={{ fontSize: '1.3rem' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleModal("stock_list", item);
                                                        }}></a>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <div className="col-md-6 col-lg-4 mx-auto text-center mt-5">
                                                <div className="card card-custom text-center py-5 border-doted-dark bg-transparent">
                                                    <div className="card-body">
                                                        <span className="bg-light-danger p-3 text-dark rounded">
                                                            <BrandingWatermark />
                                                        </span>
                                                        <h3 className="text-dark mb-0 mt-4">No Stocks have been Found</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>}
                    </PortletBody>
                </Portlet>

                {(reportsCount > limit) &&
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center px-4 cus-pagination">
                        <h5 className="text-dark mb-3 mb-md-0">{paginationTexts(activePage, reportsCount, limit)}</h5>
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={reportsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(Stocks));