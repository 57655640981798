import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { forgotPassword } from "../../crud/auth.crud";
import { TitleComponent } from "../FormComponents/TitleComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from "clsx";

class ForgotPassword extends Component {
  state = { isRequested: false, apiRes: false, loading: false };

  render() {
    const { intl } = this.props;
    const { isRequested, loading } = this.state;


    if (isRequested) {
      return <Redirect to="/admin/login" />;
    }

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1">
        <div className="kt-login__body mt-5">
          <div className="kt-login__form bg-white rounded shadow p-5">
            {/* <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </h3>
            </div> */}
            <TitleComponent title={this.props.title} icon={this.props.icon} />
            <Formik
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = "Email " + intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Email " + intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                this.setState({ loading: true });
                forgotPassword(values.email)
                  .then((response) => {
                    this.setState({ loading: false });
                    setSubmitting(false);
                    toast.success(response.data.message, {
                      className: "green-css"
                    });
                    setTimeout(() => {
                      this.setState({ isRequested: true });
                    }, 10000);
                  })
                  .catch((errors) => {
                    var msg = errors.response ? errors.response.data.message : errors.message;
                    setSubmitting(false);
                    toast.error(msg, {
                      className: "red-css"
                    });
                    this.setState({ loading: false });
                  });

              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form
                  className='kt-form form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_password_reset_form'
                  onSubmit={handleSubmit}
                >
                  <div className='text-center mb-4'>
                    {/* begin::Link */}
                    <h2 className='text-dark fw-600 mb-3'>Forgot Password ?</h2>
                    <div className='text-gray-400 font-weight-bold'><h4> Enter your email to reset your password. </h4></div>
                    {/* end::Link */}
                  </div>

                  {/* begin::Form group */}
                  <div className='fv-row'>
                    <label className='form-label fw-600 text-dark'>Email</label>
                    <input
                      name='email'
                      type='email'
                      placeholder='Email'
                      autoComplete='off'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': touched.email && errors.email },
                        {
                          'is-valid': touched.email && !errors.email,
                        }
                      )}
                    />
                    {touched.email && errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className='d-flex justify-content-center align-items-center'>
                    <div className="kt-login__actions mb-0">

                    <button
                      type='submit'
                      id='kt_password_reset_submit'
                      className='btn btn-primary btn-elevate kt-login__btn-primary'
                    >
                      <span className='indicator-label'>Submit</span>
                      {loading && (
                        <span className='indicator-progress'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <Link to='/auth'>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-outline-primary border-0 btn-elevate kt-login__btn-secondary'
                        disabled={isSubmitting}
                      >
                        Cancel
                      </button>
                    </Link>{' '}
                    </div>
                  </div>
                  {/* end::Form group */}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
