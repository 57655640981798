import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import UserProfile from "../../../app/partials/layout/UserProfile";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();
  headerMobileCssClasses = this.props.htmlClassService.classes.header_mobile.join(
    " "
  );
  headerMobileAttributes = this.props.htmlClassService.attributes.aside_menu;

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const { asideDisplay, isAdmin } = this.props;
    const redirectPath = (isAdmin === false) ? "/dashboard" : "/admin/users";
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${this.headerMobileCssClasses}`}
        {...this.headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link to={redirectPath}>
            {/* <img alt="logo" src={headerLogo} /> */}
            <img
              style={{ height: 60, width: 60 }}
              className="kt-header__brand-logo-default"
              alt="logo"
              src={require("assets/img/logo60.png")}
            />
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">
          {asideDisplay && (
            <div className="row">
              <UserProfile showAvatar={false} showHi={true} showBadge={true} /><button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left border-0 bg-transparent "
              id="kt_aside_mobile_toggler"
            >
              <i className="flaticon2-menu" style={{ color: "white" }} />
            </button>
            
            </div>
          )}
        </div>
                      
       
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  }
});

export default connect(mapStateToProps)(HeaderMobile);
