import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { changePassword } from "../../crud/auth.crud";
import { TitleComponent } from "../FormComponents/TitleComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from "clsx";

import queryString from 'query-string';
var pwdValid = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
var space = /\s/;

class ChangePassword extends Component {
  state = { isRequested: false, apiRes: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;


    if (isRequested) {
      return <Redirect to="/admin/login" />;
    }

    const queryValues = this.props.location && queryString.parse(this.props.location.search);

    return (
      <div className="kt-login__body">

        <TitleComponent title={this.props.title} icon={this.props.icon} />
        <div className="kt-login__form bg-white rounded shadow p-5">
          {/* <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.CHANGEPASSWORD.TITLE" />
            </h3>
          </div> */}

          <Formik
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validate={values => {
              const errors = {};



              if (!values.password) {
                errors.password = "Password " + intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!pwdValid.test(values.password)
              ) {
                errors.password = "Password must contain at least 8 characters, one uppercase, one number and one special case character "
              } else if (space.test(values.password)
              ) {
                errors.password = "Password can't contain space"
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = "Confirm Password " + intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
              if (
                !pwdValid.test(values.confirmPassword)
              ) {
                errors.confirmPassword = "Confirm Password must contain at least 8 characters, one uppercase, one number and one special case character "
              } else if (space.test(values.confirmPassword)
              ) {
                errors.password = "Confirm Password can't contain space"
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword =
                  "Password and Confirm Password didn't match.";
              }
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {

              setTimeout(() => {
                changePassword(
                  queryValues,
                  values.password
                )
                  .then(result => {
                    this.setState({ apiRes: true });
                    setSubmitting(false);
                    toast.success(result.data.message, {
                      className: "green-css"
                    });
                    setTimeout(() => {
                      this.setState({ isRequested: true });
                    }, 2000);
                  })
                  .catch((errors) => {
                    var msg = errors.response && errors.response.data.message;
                    setSubmitting(false);
                    toast.error(msg, {
                      className: "red-css"
                    });
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form className={'kt-form form w-100 fv-plugins-bootstrap5 fv-plugins-framework'}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off">

                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className='text-center mb-4'>
                  {/* begin::Link */}
                  <h2 className='text-dark fw-600 mb-3'>Change Password</h2>
                  {/* end::Link */}
                </div>

                <div className='fv-row mb-10'>
                  <div className='d-flex justify-content-between mt-4'>
                    <div className='d-flex flex-stack mb-2'>
                      {/* begin::Label */}
                      <label className='form-label fw-600 text-dark fs-6 mb-0'>Password</label>
                      {/* end::Label */}
                    </div>
                  </div>
                  <input
                    name='password'
                    type='password'
                    placeholder="Password"
                    autoComplete='off'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': touched.password && errors.password,
                      },
                      {
                        'is-valid': touched.password && !errors.password,
                      }
                    )}
                  />
                  {touched.password && errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-10'>
                  <div className='d-flex justify-content-between mt-4'>
                    <div className='d-flex flex-stack mb-2'>
                      {/* begin::Label */}
                      <label className='form-label fw-600 text-dark fs-6 mb-0'>Confirm Password</label>
                      {/* end::Label */}
                    </div>
                  </div>
                  <input
                    name='confirmPassword'
                    type='password'
                    placeholder="Confirm Password"
                    autoComplete='off'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': touched.confirmPassword && errors.confirmPassword,
                      },
                      {
                        'is-valid': touched.confirmPassword && !errors.confirmPassword,
                      }
                    )}
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.confirmPassword}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="kt-login__actions">

                  <Link to="/admin">
                    <button type="button" className="btn btn-outline-primary border-0 btn-elevate kt-login__btn-secondary">
                      Back
                    </button>
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className='btn btn-primary btn-elevate kt-login__btn-primary'
                  >
                    Update Password
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ChangePassword)
);
