/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import {
    getConfig, updateConfig
} from "../../crud/auth.crud";
import { Close } from '@material-ui/icons';
import { TitleComponent } from "../FormComponents/TitleComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class GeneralConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            genderArr: [],
            ageArr: [],
            groupArr: [],
            raceArr:[],
            errorGender: "",
            errorAge: "",
            errorGroup: "",
            errorRace: ""
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        this.getConfig();
    }

    getConfig = async (searchData) => {
        const { authToken } = this.props;
        await getConfig(authToken).then(result => {
            this.setState({
                genderArr: result.data.payload ? result.data.payload.gender : [],
                ageArr: result.data.payload ? result.data.payload.age_group : [],
                groupArr: result.data.payload ? result.data.payload.group_segment : [],
                raceArr: result.data.payload ? result.data.payload.race_group : [],
            });
        }).catch(err => {
            if (err.response && (err.response.data.message === "jwt expired")) {
                window.location.href = "/admin/logout";
            }
        });
    }

    updateConfig = async (key, values, errorKey) => {
        var arr = values?.filter((item) => item.trim() != "");
        var isEmpty = values?.filter((item) => item == "");
        if (isEmpty.length > 0) {
            this.setState({
                [errorKey]: "Please provide proper data, field should not be empty"
            });
        } else {
            const { authToken } = this.props;
            var postdata = { [key]: JSON.stringify(arr) };
            await updateConfig(authToken, postdata).then(result => {
                toast.success(result.data.message, {
                    className: "green-css"
                });
            }).catch(errors => {
                var msg = errors.response ? errors.response.data.message : errors.message;
                toast.error(msg, {
                    className: "red-css"
                });
            });
            setTimeout(() => {
                this.getConfig();
            }, 500);
        }

    }

    preventFloat = (e) => {
        if (e.key === 'e' || e.key === "." || e.key === "+" || e.key === "-") {
            e.preventDefault();
        }
    }

    // handleChange = (key, value) => {
    //     this.setState({ [key]: value });
    // }

    handleReset = () => {
        window.location.reload();
    }

    handleChange = (e, key, index) => {
        var dup = this.state[key];
        dup[index] = e.target.value;
        this.setState({ [key]: dup });
    }

    handleStatus = (value) => {
        this.setState({ status: value });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleBrandStatus = (value) => {
        this.setState({ brand_id: value ? value : "" });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }


    addConfig = (orgArray, key) => {
        var values = this.state[orgArray];
        var isEmpty = values?.filter((item) => item == "");
        var isNumber = values?.filter((item) => !/^[a-zA-Z]+$/i.test(item) == true);

        if (orgArray.includes('genderArr')) {
            if (isEmpty.length > 0) {
                this.setState({
                    [key]: "Please provide proper data, field should not be empty"
                });
            } else if (isNumber.length > 0) {
                this.setState({
                    [key]: "Please provide valid gender"
                });
            } else {
                var arr = []
                arr.push("");
                var d = this.state[orgArray].concat(arr)
                this.setState({ [orgArray]: d });
            }
        } else {
            if (isEmpty.length > 0) {
                this.setState({
                    [key]: "Please provide proper data, field should not be empty"
                });
            } else {
                var arr = []
                arr.push("");
                var d = this.state[orgArray].concat(arr)
                this.setState({ [orgArray]: d });
            }
        }
    }

    removeConfig = (orgArray, value, index, errorKey) => {
        var array = this.state[orgArray];
        if (array.length <= 1) {
            var msg = 'Please enter atleast one';
            toast.error(msg, {
                className: "red-css"
            });
        } else {
            var arr1 = array.filter((item, ind) => {
                if (item === value && ind == index) { } else {
                    return item
                }
            });
            this.setState({ [orgArray]: arr1, [errorKey]: "" });
        }
    }

    render() {
        const { genderArr, ageArr, groupArr, isFocus, errorGender, errorBrand, errorAge, errorGroup, errorCompany,raceArr,errorRace } = this.state;
        const customStyle = isFocus ? styleSearch : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <ToastContainer />
                <Portlet className={'bg-transparent shadow-none'}>
                    <PortletBody className={'px-4'}>
                        <div className="row">
                            <div className="col-md-6 mt-5 mt-xl-0">
                                <div className="border bg-white">
                                    <div className="portlet-title border-bottom border-secondary p-4">
                                        <div className="caption">
                                            <span className="text-dark font-weight-bold  font-25 uppercase">Gender</span>
                                        </div>
                                    </div>
                                    <div className="portlet-body p-5">
                                        <div className="form-group">
                                            <label className="control-label">Gender</label>
                                            <div className="h-220px overflow-auto">
                                                {genderArr.length > 0 &&
                                                    genderArr?.map((item, index) =>
                                                        <div className="position-relative mt-3" key={index}>
                                                            <input type="text" placeholder="gender" className="form-control"
                                                                value={item} onChange={(e) => this.handleChange(e, "genderArr", index)} />
                                                            <Close className="close-icon" onClick={() =>
                                                                this.removeConfig("genderArr", item, index, "errorGender")} />
                                                        </div>
                                                    )}
                                                {Boolean(errorGender) && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{errorGender}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <button className="btn btn-primary mt-5" onClick={() =>
                                                this.addConfig("genderArr", "errorGender")}>
                                                <i className="fa fa-plus"></i> Add new gender</button>
                                        </div>
                                        <div className="margin-top-10">
                                            <button
                                                className="btn btn-success" onClick={() =>
                                                    this.updateConfig("gender", genderArr, "errorGender")}>Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-5 mt-xl-0">
                                <div className="border bg-white">
                                    <div className="portlet-title border-bottom border-secondary p-4">
                                        <div className="caption">
                                            <span className="text-dark font-weight-bold  font-25 uppercase">Age Group</span>
                                        </div>
                                    </div>
                                    <div className="portlet-body p-5">
                                        <div className="form-group">
                                            <label className="control-label">Age Group</label>
                                            <div className="h-220px overflow-auto">
                                                {ageArr.length > 0 &&
                                                    ageArr?.map((item, index) =>
                                                        <div className="position-relative mt-3" key={index}>
                                                            <input type="text" placeholder="age group" className="form-control"
                                                                value={item} onChange={(e) => this.handleChange(e, "ageArr", index)} />
                                                            <Close className="close-icon" onClick={() =>
                                                                this.removeConfig("ageArr", item, index, "errorAge")} />
                                                        </div>
                                                    )}
                                                {Boolean(errorAge) && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{errorAge}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <button className="btn btn-primary mt-5" onClick={() =>
                                                this.addConfig("ageArr", "errorAge")}>
                                                <i className="fa fa-plus"></i> Add new age group</button>
                                        </div>
                                        <div className="margin-top-10">
                                            <button
                                                className="btn btn-success" onClick={() =>
                                                    this.updateConfig("age_group", ageArr, "errorAge")}>Save Changes </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-5">
                                <div className="border bg-white">
                                    <div className="portlet-title border-bottom border-secondary p-4">
                                        <div className="caption">
                                            <span className="text-dark font-weight-bold  font-25 uppercase">Group Segment</span>
                                        </div>
                                    </div>
                                    <div className="portlet-body p-5">
                                        <div className="form-group">
                                            <label className="control-label">Group Segment</label>
                                            <div className="h-220px overflow-auto">
                                                {groupArr.length > 0 &&
                                                    groupArr?.map((item, index) =>
                                                        <div className="position-relative mt-3" key={index}>
                                                            <input type="text" placeholder="group segment" className="form-control"
                                                                value={item} onChange={(e) => this.handleChange(e, "groupArr", index)} />
                                                            <Close className="close-icon" onClick={() =>
                                                                this.removeConfig("groupArr", item, index, "errorGroup")} />
                                                        </div>
                                                    )}
                                                {Boolean(errorGroup) && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{errorGroup}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <button className="btn btn-primary mt-5" onClick={() =>
                                                this.addConfig("groupArr", "errorGroup")}>
                                                <i className="fa fa-plus"></i> Add new group segment</button>
                                        </div>
                                        <div className="margin-top-10">
                                            <button
                                                className="btn btn-success" onClick={() =>
                                                    this.updateConfig("group_segment", groupArr, "errorGroup")}>Save Changes </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-5">
                                <div className="border bg-white">
                                    <div className="portlet-title border-bottom border-secondary p-4">
                                        <div className="caption">
                                            <span className="text-dark font-weight-bold  font-25 uppercase">Race Group</span>
                                        </div>
                                    </div>
                                    <div className="portlet-body p-5">
                                        <div className="form-group">
                                            <label className="control-label">Race Group</label>
                                            <div className="h-220px overflow-auto">
                                                {raceArr.length > 0 &&
                                                    raceArr?.map((item, index) =>
                                                        <div className="position-relative mt-3" key={index}>
                                                            <input type="text" placeholder="race group" className="form-control"
                                                                value={item} onChange={(e) => this.handleChange(e, "raceArr", index)} />
                                                            <Close className="close-icon" onClick={() =>
                                                                this.removeConfig("raceArr", item, index, "errorRace")} />
                                                        </div>
                                                    )}
                                                {Boolean(errorRace) && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{errorRace}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <button className="btn btn-primary mt-5" onClick={() =>
                                                this.addConfig("raceArr", "errorRace")}>
                                                <i className="fa fa-plus"></i> Add new race group</button>
                                        </div>
                                        <div className="margin-top-10">
                                            <button
                                                className="btn btn-success" onClick={() =>
                                                    this.updateConfig("race_group", raceArr, "errorRace")}>Save Changes </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default connect(mapStateToProps)(GeneralConfig);