import React from "react";
import { connect } from "react-redux";
import {
    getUserByToken, editProfile, resetPwdAdmin
} from "../../crud/auth.crud";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import clsx from "clsx";
import {
    Button,
    Form,
    Col,
    Modal,
    InputGroup
} from "react-bootstrap";
import { TitleComponent } from "../FormComponents/TitleComponent";
import { DropzoneArea } from 'material-ui-dropzone';
import { PictureAsPdf, OndemandVideo, CameraAltOutlined } from '@material-ui/icons';
import { editUserImg } from "../../crud/auth.crud";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actions } from "../../store/ducks/auth.duck";
import "../../../_metronic/_assets/sass/pages/pricing/pricing-3.scss";
var pwdValid = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
var space = /\s/;

class UpdateProfile extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            activeTab: 0,
            isFocus: false,
            showPasswordForm: false,
            show: false,
            currentUser: null
        };
    }

    updateUser = async () => {
        const { authToken } = this.props;
        await getUserByToken(authToken).then(result => {
            if (result.data.success) {
                var userD = result.data && result.data.payload;
                this.props.requestUser(userD);
            }
        }).catch(err => {
            console.log(err.response, err.message)
        });
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    editUserImg = async (values, setSubmitting, key) => {
        const { authToken } = this.props;
        const data = new FormData();
        var postdata = values;
        postdata.id = this.state.currentUser.id;

        if (postdata.profile_picture) {
            data.append('file', postdata.profile_picture);
            delete postdata.profile_picture;
        }
        data.append('data', JSON.stringify(postdata));

        await editUserImg(authToken, data).then(result => {
            setSubmitting(false);
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.updateUser();
        }, 500);
    }

    handleSubmit = async (values, setSubmitting) => {

        const { authToken } = this.props;
        delete values.profile_picture

        const user = {
            ...values
        }
        await editProfile(authToken, user).then(result => {
            setSubmitting(false);
            if (result.data.success) {
                toast.success(result.data.message, {
                    className: 'green-css'
                });
            } else {
                toast.error(result.data.message, {
                    className: 'red-css'
                });
            }
        }).catch(err => {
            setSubmitting(false);
            toast.error("Not able to update profile.", {
                className: 'red-css'
            });
        });

        setTimeout(() => {
            this.updateUser();
        }, 500);
    }

    handlePassword = async (values, setSubmitting) => {

        const { authToken } = this.props;
        const user = {
            email: values.email,
            password: values.password
        }

        await resetPwdAdmin(authToken, user).then(result => {
            setSubmitting(false);
            if (result.data.success) {
                toast.success(result.data.message, {
                    className: 'green-css'
                });
            } else {
                toast.error(result.data.message, {
                    className: 'red-css'
                });
            }
        }).catch(err => {
            setSubmitting(false);

            toast.error("Not able to change your password.", {
                className: 'red-css'
            });
        });
        setTimeout(() => {
            window.location.reload();
        }, 5000);

    }

    handleActiveTab = (value) => {
        this.setState({
            activeTab: value
        })
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }


    setPasswordForm = (value) => {
        this.setState({
            showPasswordForm: value
        })
    }

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false });
    }

    handleModal = (value, currentUser) => {
        this.setState({ modalType: value, show: true, currentUser });
    }

    preventFloat = (e) => {
        if (e.key === 'e' || e.key === "." || e.key === "+" || e.key === "-") {
            e.preventDefault();
        }
    }

    renderModalBody = () => {
        const { isFocus, modalType, currentUser } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };
        const handlePreviewIcon = (fileObject, classes) => {
            const { type } = fileObject.file

            const iconProps = {
                className: classes.image,
            }

            if (type.startsWith("video/")) return <OndemandVideo {...iconProps} />
            if (type.startsWith("image/")) return <img className={classes.previewImg} role="presentation" src={fileObject.data} />
            if (type === "application/pdf") return <PictureAsPdf {...iconProps} />
        }

        const isEdit = modalType === "Edit" ? true : false;
        if (modalType === "EditProfile") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>{modalType} Image</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};

                            return errors;
                        }}
                        enableReinitialize
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if (!values.profile_picture) {
                                setStatus(
                                    "Please provide User Image for this User"
                                );
                                setSubmitting(false);
                            } else {
                                this.editUserImg(values, setSubmitting);
                            }
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{
                            profile_picture: isEdit ? currentUser && currentUser.profile_picture : null,
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            status,
                            errors,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="row pt-0">
                                    <div className='fv-row mb-4 col-12'>
                                        <label className='form-label mb-0 fw-600 text-dark'>Profile Image</label>
                                        <DropzoneArea
                                            dropzoneText={(isEdit && currentUser.profile_picture) ?
                                                "Drag and drop a image here for replacing your existing thumbnail" :
                                                "Drag and drop a image here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("profile_picture", files[0])}
                                        />
                                        {status && (<div className="invalid-msg">{status}</div>)}
                                    </div>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="success" disabled={isSubmitting}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>)}
                    </Formik>
                </div>
            );
        }
    }

    render() {
        const { isFocus, activeTab } = this.state;
        const { user } = this.props;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 1, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 1, backgroundColor: 'transparent', color: 'black' };
        const { showPasswordForm, setPasswordForm } = this.state;
        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <ToastContainer />
                <Portlet>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3 px-5 pt-5'>
                        <div className='me-7 mb-4'>
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                {user.profile_picture ?
                                    <img src={user.profile_picture} className={'rounded w-110px h-110px'} alt='Metronic' />
                                    :
                                    <img src={('/media/users/default.jpg')} className={'rounded w-110px h-110px'} alt='Metronic' />
                                }
                                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                            </div>
                        </div>

                        <div className='flex-grow-1'>
                            <div className='d-flex flex-column ml-3'>
                                <div>
                                    <div className='text-primary fw-600'>
                                        <h4> {user.username} </h4>
                                    </div>
                                </div>

                                <div>
                                    <div className='text-dark'>
                                        {(user.role_id === 0) && <p>Super Admin</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PortletHeader
                        toolbar={
                            <PortletHeaderToolbar className="col-md-12">
                                <div className={(activeTab === 0) ? "col-auto text-center tab-text tab-active cursor-pointer" :
                                    "col-auto text-center tab-text cursor-pointer"} onClick={() => this.handleActiveTab(0)}>Overview</div>
                                <div className={(activeTab === 1) ?
                                    "col-auto text-center tab-text tab-active cursor-pointer" :
                                    "col-auto text-center tab-text cursor-pointer"} onClick={() => this.handleActiveTab(1)}>Settings</div>
                            </PortletHeaderToolbar>
                        }
                    />
                </Portlet>
                <Portlet>
                    <PortletBody className={'p-0'}>
                        {(activeTab === 0) ?
                            <div className={"tab-pane show active"} id="kt_user_edit_tab_1" role="tabpanel">

                                <div className='card mb-0 shadow-none border-0' id='kt_profile_details_view'>
                                    <div className='card-header cursor-pointer d-flex justify-content-between align-items-center border-bottom px-5'>
                                        <div className='card-title mb-0'>
                                            <h5 className='fw-600 text-dark mb-0'>Profile Details</h5>
                                        </div>
                                        <div onClick={() => this.handleActiveTab(1)} className='btn btn-primary'>
                                            Edit Profile
                                        </div>
                                    </div>
                                    <Formik
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validate={values => {
                                            const errors = {};

                                            if (values.username.trim().length <= 0) {
                                                errors.username = "Please provide valid username"
                                            }
                                            if (values.contact_number.length < 8) {
                                                errors.contact_number =
                                                    "Please enter atleast 8 to 12 digit number";
                                            } else if (values.contact_number.length > 12) {
                                                errors.contact_number =
                                                    "Please enter atleast 8 to 12 digit number";
                                            }

                                            return errors;
                                        }}
                                        onSubmit={(values, { setStatus, setSubmitting }) => {
                                            this.handleSubmit(values, setSubmitting);
                                        }}
                                        initialValues={{
                                            email: user ? user.email : '',
                                            username: user ? user.username : '',
                                            contact_number: user ? user.contact_number : ''
                                        }}>
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            errors,
                                            isSubmitting
                                        }) => (
                                            <Form noValidate={true}
                                                onSubmit={handleSubmit}>
                                                <div className='card-body px-5'>
                                                    <div className='row mb-4'>
                                                        <label className='col-lg-4 text-muted'>User Name</label>

                                                        <div className='col-lg-8'>
                                                            <span className='font-weight-bold fs-6 text-dark'>{values.username}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-4'>
                                                        <label className='col-lg-4 text-muted'>Email</label>

                                                        <div className='col-lg-8'>
                                                            <span className='font-weight-bold fs-6 text-dark'>{values.email}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-4'>
                                                        <label className='col-lg-4 text-muted'>Contact Number</label>

                                                        <div className='col-lg-8'>
                                                            <span className='font-weight-bold fs-6 text-dark'>{values.contact_number}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>)}
                                    </Formik>
                                </div>
                            </div>

                            :
                            <div className={"tab-pane show px-7 active"} id="kt_user_edit_tab_2" role="tabpanel">

                                <div className={"tab-pane show active"} id="kt_user_edit_tab_1" role="tabpanel">

                                    <div className='card mb-0 shadow-none border-0' id='kt_profile_details_view'>
                                        <div className='card-header cursor-pointer py-4 border-bottom px-5'>
                                            <div className='card-title mb-0'>
                                                <h5 className='fw-600 text-dark mb-0'>Profile Details</h5>
                                            </div>
                                        </div>
                                        <Formik
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            validate={values => {
                                                const errors = {};

                                                if (values.username.trim().length <= 0) {
                                                    errors.username = "Please provide valid username"
                                                }
                                                if (values.contact_number.toString().length < 8) {
                                                    errors.contact_number = "Please enter atleast 8 to 12 digit number"
                                                } else if (values.contact_number.toString().length > 12) {
                                                    errors.contact_number = "Please enter atleast 8 to 12 digit number"
                                                }

                                                return errors;
                                            }}
                                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                                this.handleSubmit(values, setSubmitting);
                                            }}
                                            initialValues={{
                                                id: user ? user.id : '',
                                                profile_picture: user ? user.profile_picture : null,
                                                email: user ? user.email : '',
                                                username: user ? user.username : '',
                                                contact_number: user ? user.contact_number : ''
                                            }}>
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting
                                            }) => (
                                                <Form noValidate={true}
                                                    onSubmit={handleSubmit}>
                                                    <div className='card-body px-5'>
                                                        <div className='row mb-3 mb-lg-5'>
                                                            <label className='col-lg-4 col-form-label text-muted'>Avatar</label>
                                                            <div className='col-lg-auto'>
                                                                <div className="position-relative">
                                                                    {values.profile_picture ?
                                                                        <img src={values.profile_picture} className={'rounded w-110px h-110px'} alt='Metronic' />
                                                                        :
                                                                        <img src={('/media/users/default.jpg')} className={'rounded w-110px h-110px'} alt='Metronic' />
                                                                    }
                                                                    <div className="edit-image-style bg-dark rounded-circle p-1 cursor-pointer"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleModal("EditProfile", values)
                                                                        }}>
                                                                        <CameraAltOutlined />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-3 mb-lg-5'>
                                                            <label className='col-lg-4 col-form-label text-muted' htmlFor='userName'>User Name</label>

                                                            <div className='col-lg-8'>
                                                                <div className='row'>
                                                                    <div className='col-lg-6 fv-row'>
                                                                        <input
                                                                            required
                                                                            type='text'
                                                                            placeholder='User name'
                                                                            name="username"
                                                                            id="userName"
                                                                            onFocus={() => this.changeFocus()}
                                                                            value={values.username}
                                                                            onChange={handleChange}
                                                                            className={clsx(
                                                                                'form-control form-control-lg form-control-solid mb-3 mb-lg-0',
                                                                                { 'is-invalid': touched.username && errors.username },
                                                                                {
                                                                                    'is-valid': touched.username && !errors.username,
                                                                                }
                                                                            )}
                                                                        />
                                                                        {touched.username && errors.username && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>{errors.username}</div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-3 mb-lg-5'>
                                                            <label className='col-lg-4 col-form-label text-muted' htmlFor='email'>Email</label>

                                                            <div className='col-lg-8'>
                                                                <div className='row'>
                                                                    <div className='col-lg-6 fv-row'>
                                                                        <input
                                                                            required
                                                                            type='email'
                                                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                                            readOnly
                                                                            name="email"
                                                                            id="email"
                                                                            onFocus={() => this.changeFocus()}
                                                                            placeholder="Email"
                                                                            value={values.email}
                                                                        />
                                                                        {touched.email && errors.email && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>{errors.email}</div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-2'>
                                                            <label className='col-lg-4 col-form-label text-muted' htmlFor='contact_number'>Contact Number</label>

                                                            <div className='col-lg-8'>
                                                                <div className='row'>
                                                                    <div className='col-lg-6 fv-row'>
                                                                        <input
                                                                            required
                                                                            type="number"
                                                                            name="contact_number"
                                                                            id="contact_number"
                                                                            onFocus={() => this.changeFocus()}
                                                                            onKeyDown={(e) => this.preventFloat(e)}
                                                                            placeholder="Contact Number"
                                                                            value={values.contact_number}
                                                                            onChange={handleChange}
                                                                            className={clsx(
                                                                                'form-control form-control-lg form-control-solid mb-3 mb-lg-0',
                                                                                { 'is-invalid': touched.contact_number && errors.contact_number },
                                                                                {
                                                                                    'is-valid': touched.contact_number && !errors.contact_number,
                                                                                }
                                                                            )}
                                                                        />
                                                                        {touched.contact_number && errors.contact_number && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <div className='fv-help-block'>{errors.contact_number}</div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='card-footer d-flex justify-content-end px-5 border-top py-2'>
                                                        <Button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                                                            Save changes
                                                        </Button>
                                                    </div>
                                                </Form>)}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        }
                    </PortletBody>
                </Portlet>
                {(activeTab === 1) && <Portlet>
                    <PortletBody className={'p-0'}>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={values => {
                                const errors = {};

                                if (!values.password) {
                                    errors.password = "Please enter valid password";
                                }

                                if (!pwdValid.test(values.password)
                                ) {
                                    errors.password = "Password must contain at least 8 characters, one uppercase, one number and one special case character "
                                } else if (space.test(values.password)
                                ) {
                                    errors.password = "Password can't contain space"
                                }

                                if (!values.confirmPwd) {
                                    errors.confirmPwd = "Please enter valid confirm password";

                                }
                                if (
                                    !pwdValid.test(values.confirmPwd)
                                ) {
                                    errors.confirmPwd = "Confirm Password must contain at least 8 characters, one uppercase, one number and one special case character "
                                } else if (space.test(values.confirmPwd)
                                ) {
                                    errors.password = "Confirm Password can't contain space"
                                } else if (values.password !== values.confirmPwd) {
                                    errors.confirmPwd =
                                        "Password and Confirm Password didn't match.";
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                this.handlePassword(values, setSubmitting);
                            }}
                            initialValues={{
                                email: user.email,
                                password: '',
                                confirmPwd: ''
                            }}>
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                isSubmitting
                            }) => (
                                <Form noValidate={true}
                                    onSubmit={handleSubmit}
                                >
                                    <div className='card mb-5 shadow-none border-0'>
                                        <div className='card-header cursor-pointer py-4 border-bottom px-5'>
                                            <div className='card-title mb-0'>
                                                <h5 className='fw-600 text-dark mb-0'>Sign-in Method</h5>
                                            </div>
                                        </div>

                                        <div id='kt_account_signin_method'>
                                            <div className='px-5 py-3'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                                                        <div className='fs-6 fw-bolder mb-1'>Password</div>
                                                        <div className='fw-bold text-gray-600'>********</div>
                                                    </div>

                                                    <div
                                                        id='kt_signin_password_button'
                                                        className={'ms-auto ' + (showPasswordForm && 'd-none')}
                                                    >
                                                        <Button
                                                            onClick={() => this.setPasswordForm(true)}
                                                            className='btn btn-primary'
                                                        >
                                                            Change Password
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div
                                                    id='kt_signin_password_edit'
                                                    className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                                                >
                                                    <div className='row mt-2'>
                                                        <div className='col-lg-4'>
                                                            <div className='fv-row mb-0'>
                                                                <label htmlFor='newpassword' className='form-label text-dark fw-600 mb-0'>
                                                                    Password
                                                                </label>
                                                                <input
                                                                    required
                                                                    type='password'
                                                                    id='newpassword'
                                                                    name="password"
                                                                    onFocus={() => this.changeFocus()}
                                                                    placeholder="Password"
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    className={clsx(
                                                                        'form-control form-control-lg form-control-solid',
                                                                        { 'is-invalid': touched.password && errors.password },
                                                                        {
                                                                            'is-valid': touched.password && !errors.password,
                                                                        }
                                                                    )}
                                                                />
                                                                {touched.password && errors.password && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>{errors.password}</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-4'>
                                                            <div className='fv-row mb-0 mt-4 mt-lg-0'>
                                                                <label htmlFor='confirmpassword' className='form-label text-dark fw-600 mb-0'>
                                                                    Confirm Password
                                                                </label>
                                                                <input
                                                                    required
                                                                    type='password'
                                                                    className='form-control form-control-lg form-control-solid '
                                                                    id='confirmpassword'
                                                                    name="confirmPwd"
                                                                    onFocus={() => this.changeFocus()}
                                                                    placeholder="Confirm Password"
                                                                    value={values.confirmPwd}
                                                                    onChange={handleChange}
                                                                    className={clsx(
                                                                        'form-control form-control-lg form-control-solid',
                                                                        { 'is-invalid': touched.confirmPwd && errors.confirmPwd },
                                                                        {
                                                                            'is-valid': touched.confirmPwd && !errors.confirmPwd,
                                                                        }
                                                                    )}
                                                                />
                                                                {touched.confirmPwd && errors.confirmPwd && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>{errors.confirmPwd}</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='form-text mb-3'>
                                                        Password must be at least 8 character and contain symbols
                                                    </div>

                                                    <div className='d-flex'>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            id='kt_password_submit'
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Update Password
                                                        </Button>
                                                        <Button
                                                            onClick={() => this.setPasswordForm(false)}
                                                            id='kt_password_cancel'
                                                            type='button'
                                                            className='btn btn-primary text-white mx-3'
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>)}
                        </Formik>
                    </PortletBody>
                </Portlet>}
                <Modal centered size={"lg"} show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
            </div >
        );
    }
}

const mapStateToProps = ({ auth: { authToken, user } }) => ({
    authToken, user
});
const mapDispatchToProps = dispatch => {
    return {
        requestUser: topic => {
            dispatch(actions.requestUser(topic))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);