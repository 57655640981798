export default {
    header: {
      self: {},
      items: [
        {
          title: "Reports",
          root: true,
          alignment: "left",
          toggle: "click",
          submenu: [
            {
              title: "Order Reports",
              bullet: "dot",
              page: "admin/reports",
            },
            {
              title: "Outlet Reports",
              bullet: "dot",
              page: "admin/outlet-reports",
            },
            {
              title: "Export CSV Reports",
              bullet: "dot",
              page: "admin/csv-reports",
            }
          ]
        },
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: "Reports",
          root: true,
          icon: "fas fa-chart-bar",
          alignment: "left",
          bullet: "dot",
          toggle: "click",
          submenu: [
            {
              title: "Order Report",
              bullet: "dot",
              page: "reports"
            },
            {
              title: "Outlet Report",
              bullet: "dot",
              page: "outlet-reports"
            },
            {
              title: "Export CSV Reports",
              bullet: "dot",
              page: "csv-reports"
            }
          ]
        }
      ]
    }
  };
  