export default {
  header: {
    self: {},
    items: [
      {
        title: "Users",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "App Users",
            bullet: "dot",
            page: "admin/users"
          },
          {
            title: "Admin Users",
            bullet: "dot",
            page: "admin/adminusers"
          },
          {
            title: "Roles",
            bullet: "dot",
            page: "admin/roles"
          },
          {
            title: "Regions",
            bullet: "dot",
            page: "admin/regions"
          }
        ]
      },
      {
        title: "Projects",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "All Projects",
            bullet: "dot",
            page: "admin/projects",
          },
          {
            title: "Category",
            bullet: "dot",
            page: "admin/category"
          },
          {
            title: "Outlets",
            bullet: "dot",
            page: "admin/outlets"
          },
          {
            title: "Project Outlets",
            bullet: "dot",
            page: "admin/project-outlets"
          },
          
        ]
      },
      {
        title: "Products",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "All Products",
            bullet: "dot",
            page: "admin/products",
          },
          {
            title: "Brands",
            bullet: "dot",
            page: "admin/brands",
          },
          {
            title: "Brands Variant",
            bullet: "dot",
            page: "admin/brands-variant",
          },
        ]
      },
      {
        title: "General Config",
        root: true,
        alignment: "left",
        page: "admin/general-config",
      },
      {
        title: "Reports",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "Order Reports",
            bullet: "dot",
            page: "admin/reports",
          },
          {
            title: "Order Stocks",
            bullet: "dot",
            page: "admin/stocks",
          },
          {
            title: "Visitation Logs",
            bullet: "dot",
            page: "admin/visitations",
          },
          {
            title: "Trade Request",
            bullet: "dot",
            page: "admin/trade-request",
          },
          {
            title: "Collected Users",
            bullet: "dot",
            page: "admin/collected-users"
          },
          {
            title: "Outlet Reports",
            bullet: "dot",
            page: "admin/outlet-reports",
          },
          {
            title: "Export CSV Reports",
            bullet: "dot",
            page: "admin/csv-reports",
          }
        ]
      },
      {
        title: "Questionnaires",
        root: true,
        alignment: "left",
        page: "admin/questionnaires",
      },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Users",
        root: true,
        icon: "flaticon2-user",
        alignment: "left",
        bullet: "dot",
        toggle: "click",
        submenu: [
          {
            title: "App Users",
            bullet: "dot",
            page: "users"
          },
          {
            title: "Admin Users",
            bullet: "dot",
            page: "adminusers"
          },
          {
            title: "Roles",
            bullet: "dot",
            page: "roles"
          },
          {
            title: "Regions",
            bullet: "dot",
            page: "regions"
          }
        ]
      },
      {
        title: "Projects",
        root: true,
        icon: "fas fa-project-diagram",
        alignment: "left",
        bullet: "dot",
        toggle: "click",
        submenu: [
          {
            title: "All Projects",
            bullet: "dot",
            page: "projects",
          },
          {
            title: "Category",
            bullet: "dot",
            page: "category"
          },
          {
            title: "Outlets",
            bullet: "dot",
            page: "outlets"
          }
        ]
      },
      {
        title: "Products",
        root: true,
        icon: "fas fa-calendar-alt",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        submenu: [
          {
            title: "All Products",
            bullet: "dot",
            page: "products",
          },
          {
            title: "Brands",
            bullet: "dot",
            page: "brands"
          },
          {
            title: "Brands Variant",
            bullet: "dot",
            page: "brands-variant"
          }
        ]
      },
      {
        title: "General Config",
        root: true,
        icon: "fas fa-cogs",
        alignment: "left",
        page: "general-config"
      },
      {
        title: "Reports",
        root: true,
        icon: "fas fa-chart-bar",
        alignment: "left",
        bullet: "dot",
        toggle: "click",
        submenu: [
          {
            title: "Order Report",
            bullet: "dot",
            page: "reports"
          },
          {
            title: "Stock Report",
            bullet: "dot",
            page: "stocks"
          },
          {
            title: "Visitation Logs",
            bullet: "dot",
            page: "visitations"
          },
          {
            title: "Trade Request",
            bullet: "dot",
            page: "trade-request"
          },
          {
            title: "Collected Users",
            bullet: "dot",
            page: "collected-users"
          },
          {
            title: "Outlet Report",
            bullet: "dot",
            page: "outlet-reports"
          },
          {
            title: "Export CSV Reports",
            bullet: "dot",
            page: "csv-reports"
          }
        ]
      },
      {
        title: "Questionnaires",
        root: true,
        icon: "fas fa-poll",
        alignment: "left",
        page: "questionnaires"
      },
    ]
  }
};