import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import * as builder from "../../ducks/builder";
import KTOffcanvas from "../../_assets/js/offcanvas";
import Brand from "../brand/Brand"
import Menu from "./components/Menu";

class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();
  asideMenuRef = React.createRef();

  insideTm;
  outsideTm;

  componentDidMount() {
    // eslint-disable-next-line
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );
  }

  render() {
    // const perfectScrollbarOptions = {
    //   wheelPropagation: false,
    //   wheelSpeed: 2
    // };
    const { isAdmin, isVerified } = this.props;
    return (
      <>
        <button className="kt-aside-close" id="kt_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="kt_aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}

          style={{ backgroundColor: "#1e1e2d" }}
        >
          <Brand isAdmin={isAdmin} />
          <div
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_aside_menu_wrapper"
          >
            {this.props.disableScroll && (
              <Menu htmlClassService={this.props.htmlClassService} isAdmin={isAdmin} isVerified={isVerified}/>
            )}
            {!this.props.disableScroll && (
              <PerfectScrollbar
                options={{ wheelSpeed: 2, wheelPropagation: false }}
              > <Menu htmlClassService={this.props.htmlClassService} isAdmin={isAdmin} isVerified={isVerified}/>
              </PerfectScrollbar>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  ulClasses: builder.selectors.getClasses(store, {
    path: "aside_menu_nav",
    toString: true
  }),
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active"
    }
  }
});
export default withRouter(connect(mapStateToProps)(AsideLeft));
